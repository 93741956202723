.acappo {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(100, 100, 100, 0.8);
  font-size: 50px;
  color: red;
  font-weight: bold;
  z-index: 1000;
}