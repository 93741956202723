@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-container {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 6px solid #ee71a9;
    border-top: 6px solid #383636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}