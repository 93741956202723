
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gopher';
  src: url('./assets/fonts/Gopher/Gopher-Black.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cooper Black';
  src: url('./assets/fonts/Cooper Black Regular/Cooper Black Regular.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BaksoSapi';
  src: url('./assets/fonts/BaksoSapi.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gopher';
  src: url('./assets/fonts/Gopher/Gopher-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EricaOne';
  src: url('./assets/fonts/EricaOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentinel';
  src: url('./assets/fonts/Sentinel-MediumItalic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonGrotesque Light';
  src: url('./assets/fonts/Brandon-Grotesque/Brandon_light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandonGrotesque Bold';
  src: url('./assets/fonts/Brandon-Grotesque/Brandon_bld.otf');
  font-weight: normal;
  font-style: normal;
}

body {}

h1 {
  font-family: 'Gopher';
  /*color: #ffb4cb;*/
  z-index: 2;
  font-size: 42px;
  margin-bottom: 0;
  font-weight: bold;
}

p {
  font-family: 'Gopher';
}

.text-block {
  h1, h2 {
    font-family: 'Gopher';
  }

  width: 60%;
  margin: 0 auto;
  &.center {
    text-align: center;
  }
  color: white;
  &.dark-text {
    color: black;
  }
}

.content-frame {
  width: 50%;
  margin: 0 auto 40px;
  p {
    margin-top: 25px;
    color: white;
    font-family: Arial;
    font-size: 14px;
    text-align: center;
    font-family: 'Gopher';
    font-weight: normal;
  }
}
@media (max-width: 991.98px) {  
  .content-frame {
    width: 90%;
    p {
      margin-top: 0;
    }
  }
}

.page-content {
  padding: 30px 0;
  background: pink;
}
